<template>
  <v-container>
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs6 sm4 md4 lg4 class="py-1 px-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-divider></v-divider>

    <v-layout row wrap justify-space-between class="mt-1">
      <v-flex xs12 sm4 md4 lg4 v-if="!selected" class="py-1 px-1"
        ><v-select
          dense
          solo
          class="caption mr-4"
          :label="$t('select business')"
          item-text="businessName"
          item-value="id"
          :items="ownersBusiness"
          v-model="selectedShop"
          @change="getOrdersSelected"
        ></v-select>
      </v-flex>
      <v-flex xs12 sm4 md4 lg4 class="text-right py-1 px-1">
        <v-text-field
          v-model="search"
          dense
          solo
          :placeholder="$t('search purchase order')"
        ></v-text-field>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex
        xs12
        sm4
        md4
        lg4
        class="py-2 text-right"
        v-if="this.selectedShop || this.selected"
      >
        <v-btn
          :color="$store.state.primaryColor"
          outlined
          small
          @click="toPurchaseOrder"
        >
          {{ $t("new purchase order") }}
        </v-btn>
      </v-flex>
    </v-layout>

    <v-row justify="center">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :loading="loading"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items="orders"
          :search="search"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip bottom color="blue lighten-3">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  left
                  v-bind="attrs"
                  v-on="on"
                  @click="viewOrder(item.id)"
                  :color="$store.state.primaryColor"
                  >visibility</v-icon
                >
              </template>
              <span>{{ $t("details") }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.dateTime`]="{ item }">
            <span>{{ formatDate(item.dateTime) }}</span>
          </template>

          <template v-slot:[`item.isActive`]="{ item }">
            <v-icon v-if="item.isActive" color="success darken-3"
              >fas fa-check-circle</v-icon
            >
            <v-icon v-else color="error darken-3">fas fa-times-circle</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";
import { format } from "date-fns";

export default {
  data: () => ({
    loading: true,
    search: "",
    sortBy: "dateTime",
    sortDesc: true,

    orders: [],
    selectedShop: "",

    businesses: [],
    previewPic:
      "https://cdn.pixabay.com/photo/2014/04/03/00/42/house-309156_960_720.png",
  }),

  computed: {
    selected() {
      return this.$store.getters.getCurrentBusiness;
    },
    ownersBusinessIds() {
      return this.$store.getters.getBusinessId;
    },
    ownersBusiness() {
      return this.$store.getters.getBusinesses;
    },
    headers() {
      return [
        {
          text: this.$t("purchase order"),
          value: "orderNo",
          class: "blue-grey darken-3 white--text font-weight-medium",
        },
        {
          text: this.$t("created on"),
          value: "dateTime",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("active"),
          value: "isActive",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("actions"),
          value: "action",
          class:
            "blue-grey darken-3 white--text font-weight-regular text-center",
          sortable: false,
        },
      ];
    },
  },

  created() {
    this.getOrders();
  },

  methods: {
    selectShop() {
      this.$router.push({ name: "SelectBusiness" });
    },

    getOrders() {
      if (this.selected) {
        db.collection("purchaseOrders")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.orders.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.loading = false;
          });
      } else {
        db.collection("purchaseOrders")
          .where("businessId", "in", this.ownersBusinessIds)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.orders.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.loading = false;
          });
      }
    },

    getOrdersSelected() {
      this.$store.commit("SET_SELECTED_BUSINESS_TEMP", "");
      //keep store details in temporary store
      this.$store.commit("SET_SELECTED_BUSINESS_TEMP", this.selectedShop);
      this.orders = [];
      db.collection("purchaseOrders")
        .where("businessId", "==", this.selectedShop)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.orders.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          this.loading = false;
        });
    },

    getBusinessName(id) {
      let busId = this.$store.getters.getBusinesses.find(
        (item) => item.id == id
      );
      let busName = busId.businessName;
      return busName;
    },

    viewOrder(odID) {
      this.$router.push({ name: "PurchaseOrderDetails", params: { id: odID } });
    },

    toPurchaseOrder() {
      this.$router.push({ name: "CreatePurchaseOrder" });
    },

    goBack() {
      this.$router.push({ name: "StockMenu" });
    },
    getStatus(val) {
      val == true ? "Active" : "Closed";
    },

    formatDate(val) {
      let date = val.toDate();
      return format(date, "EEE, dd MMMM, yyyy");
    },
  },
};
</script>

<style></style>
